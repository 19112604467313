<template>
  <div class="faq padding-x">
    <Heading noDecoration text-align="center" class="faq__header">
      FAQ
    </Heading>
    <div
      v-for="(item, index) in faqList"
      :key="`item-${index}`"
      class="faq__item"
    >
      <div class="faq__item-num">{{ showNum(index) }}</div>
      <div>
        <div class="faq__item-title">{{ item.title }}</div>
        <div class="faq__item-description">{{ item.description }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Heading from "../common/Heading";
export default {
  name: "SponsorFAQ",
  props: {},
  components: { Heading },
  data: () => ({
    faqList: [
      {
        title: "Can I purchase tickets for my group for the ArcticCon event?",
        description:
          "Yes, you can purchase tickets for your group. You can go through the normal purchasing process that you do when you purchase tickets for yourself. Simply increase the quantity of tickets that you want to purchase and send the registration code to your group so that they can all register for the event.",
      },
      {
        title: "Can I cancel my Arcticon ticket?",
        description:
          "Yes, ArcticCon tickets are cancellable. To cancel your ticket, please contact one of our support staff at inform@arcticcon.com and tell them what your Ticket ID is.",
      },
      {
        title: "Can I transfer my ticket?",
        description:
          "Yes, ArcticCon tickets are transferrable. To transfer your ticket to another potential participant, please contact our support staff at inform@arcticcon.com and present the following information about the first name, last name, and the email address of the person you will transfer the ticket to. Please also mention what your Ticket ID is.",
      },
      {
        title: "What will I expect in an ArcticCon seminar? ",
        description:
          "ArcticCon seminars offer you different hands-on and experiential activities that will help you understand the world of cybersecurity. You will be led to different villages, which are different learning areas that correspond to a specific skill that you might be interested to explore.",
      },
      // {
      //   title: "How can I be accepted to become a speaker for your talk?",
      //   description: "If you want to be a speaker at ____, please submit a proposal for your talk using the link below _______. Please note that there are 3 rounds of acceptance that are being followed before you will be accepted as a speaker. Our team will reach out to you once your talk is approved. If you want to check the status of your application, please contact us anytime at info@articcom.com.",
      // },
      // {
      //   title:
      //     "As a speaker, is there any template that I should use for my talks?",
      //   description:
      //     "No, there is no specific template to be used during talks. However, there are guidelines that should be followed. For example, speakers should remove their company logo in their presentation.",
      // },
      {
        title:
          "Do you provide free hotel accommodations for your sponsors and speakers?",
        description:
          "No, we do not provide hotel accommodations for our sponsors and speakers. However, we provide our sponsors and speakers with a list of hotels that offer discounted rates during their stay. Once sponsors receive their exhibitor’s exhibitorKit, they will be provided with a link that shows the list of hotels with discounted rates. The protocol is the same once speakers receive their information exhibitorKit from us.",
      },
      {
        title: "Will I receive a certificate of attendance?",
        description:
          "Yes, all participants will receive a certificate of attendance at the culmination of the program.",
      },
      // {
      //   title:
      //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua?",
      //   description:
      //     "Consectetur adipiscing elit pellentesque habitant morbi. Neque vitae tempus quam pellentesque nec. Mattis rhoncus urna neque viverra justo nec ultrices dui. Velit aliquet sagittis id consectetur purus ut faucibus pulvinar. Senectus et netus et malesuada fames ac turpis egestas integer. ",
      // },
    ],
  }),
  computed: {},
  methods: {
    showNum(index) {
      if (index < 9) return "0" + (index + 1);
      return index + 1;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";
.faq {
  padding-top: 20px;
  $self: &;

  &__header {
    padding-bottom: 30px;
  }

  &__item {
    position: relative;
    padding-bottom: 40px;
    display: flex;

    &-num {
      font-family: $sarabun;
      font-weight: bold;
      font-size: 120px;
      line-height: 120px;
      letter-spacing: 0.05em;
      color: $light-gray;
      margin-right: 53px;
      z-index: -1;
      position: absolute;
      top: -15%;
    }

    &-title {
      padding-bottom: 14px;
      font-weight: bold;
      line-height: 32px;
      letter-spacing: 0.05em;
      color: $dark-gray;
    }

    &-description {
      line-height: 32px;
      letter-spacing: 0.05em;
    }
  }

  @media (min-width: $media-sm) {
    padding-top: 100px;

    &__header {
      padding-bottom: 70px;
    }
    &__item {
      &-num {
        position: static;
      }
    }
  }

  @media (min-width: $media-lg) {
    &__header {
      padding-bottom: 95px;
    }
  }
}
</style>
