<template>
  <div class="hero padding-x">
    <div class="hero__img" :style="`background-image: url(${image}`" />
    <div class="hero__wrapper">
      <div>
        <Heading
          v-html="getDecoratedTitle(title)"
          noDecoration
          type="h1"
          class="mt-2 hero__heading"
        />
        <p class="hero__description">{{ description }}</p>
      </div>
      <div>
        <div v-if="box" class="hero__box">
          <h5 class="hero__box-heading">{{ box.name }}</h5>
          <div class="hero__box-text pl-2">
            {{ box.title }}
            <div>{{ box.subtitle }}</div>
          </div>
        </div>
        <slot name="button" />
      </div>
    </div>
  </div>
</template>

<script>
import Heading from "@/components/common/Heading";

export default {
  name: "HalfImageHero",
  props: {
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: "",
    },
    box: {
      type: Object,
      default: () => ({}),
    },
  },
  components: { Heading },
  data: () => ({}),
  methods: {
    getDecoratedTitle(title) {
      const titleArr = title.split(" ");
      if (titleArr.length < 2) return title;
      const decor = titleArr.pop();
      return `${titleArr.join(" ")}<div class="heading-decor">${decor}</div>`;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";
.hero {
  $self: &;

  position: relative;
  padding-top: 260px;

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 260px;
    background-position: center;
    /*background-position: 80% center;*/
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__heading {
    text-transform: uppercase;
    word-wrap: break-word;
  }

  &__description {
    padding: 6% 0 4% 0;
  }

  &__box {
    position: relative;
    margin-left: 10%;

    &:first-of-type {
      margin-bottom: 3%;
      min-width: 115px;
    }
  }

  &__box-heading {
    position: absolute;
    top: 31%;
    left: -12%;
    font-size: 16px;
    line-height: 16px;
    font-weight: bold;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: $dark-gray;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: $dark-gray;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }

  &__box-text {
    font-family: $sarabun;
    overflow: hidden;
    font-size: 18px;
    font-weight: $sarabunSemibold;
    line-height: 32px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: $dark-gray;

    div {
      font-family: $openSans;
      font-size: 0.5em;
      font-weight: 400;
      text-transform: initial;
    }
  }

  @media (min-width: $media-xs) {
    padding-top: 160px;

    &__img {
      height: 160px;
    }
  }

  @media (min-width: $media-sm) {
    min-height: 100vh;
    height: 100%;
    padding-top: 140px;
    padding-bottom: 40px;

    &__img {
      right: 0;
      left: unset;
      width: 50%;
      min-height: 100vh;
      height: 100%;
    }

    &__wrapper {
      width: 50%;
      padding-right: 24px;
      min-height: calc(100vh - 140px);
      display: flex;
      flex-flow: column;
      justify-content: space-between;
    }

    &__heading {
      margin-bottom: 16px;
    }

    &__box {
      &:first-of-type {
        margin-bottom: 10%;
      }
    }

    &__box-heading {
      left: -18%;
      font-size: 24px;
      line-height: 24px;
    }

    &__box-text {
      white-space: unset;
      overflow: unset;
    }
  }

  @media (min-width: $media-md) {
    padding-bottom: 8px;

    &__img {
      width: 57%;
    }

    &__wrapper {
      width: 43%;
    }
  }

  @media (min-width: $media-lg) {
    padding-bottom: 80px;

    &__img {
      width: 50%;
    }

    &__wrapper {
      width: 50%;
    }

    &__heading {
      margin-bottom: 24px;
    }

    &__text {
      margin-bottom: 72px;
    }

    &__description {
      padding: 11% 0 19% 0;
    }

    &__box-heading {
      font-size: 26px;
      line-height: 26px;
    }
    &__box-text {
      font-size: 32px;
      line-height: 40px;
    }
  }

  @media (min-width: $media-xl) {
    &__box-heading {
      left: -15%;
    }
  }
}
</style>
