<template>
  <HalfImageHero
    :title="conferenceName"
    :description="conferenceDescription"
    :image="require('@/assets/img/sponsor_bg.png')"
  >
    <template #button>
      <router-link style="text-decoration: none" to="#sponsor-acquire">
        <Button fullWidth type="text"> become a sponsor </Button>
      </router-link>
    </template>
  </HalfImageHero>
</template>

<script>
import Button from "@/components/common/Button";
import HalfImageHero from "@/components/common/HalfImageHero";

export default {
  name: "SponsorHero",
  props: {},
  components: {
    Button,
    HalfImageHero,
  },
  data: () => ({
    conferenceName: "Arctic - Con",
    conferenceDescription:
      "ArcticCon is an IT conference based in Anchorage, Alaska with its start in 2017. ArcticCon’s mission is to provide technical information from industry experts to educate the IT community of Alaska about new advancements in Cyber Security and Information Technology. ArcticCon aims to make IT more accessible to the Last Frontier and bring together community members from all over the state to encourage a collaborative work environment.",
  }),
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";
</style>
