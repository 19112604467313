<template>
  <div class="container padding-x">
    <div
      class="block block-1"
      :style="`background-image: url(${getImage(1)})`"
    />
    <div
      class="block block-2"
      :style="`background-image: url(${getImage(2)})`"
    />
    <div
      class="block block-3"
      :style="`background-image: url(${getImage(3)})`"
    />
    <div
      class="block block-4"
      :style="`background-image: url(${getImage(4)})`"
    />
    <div
      class="block block-5"
      :style="`background-image: url(${getImage(5)})`"
    />
    <div
      class="block block-6"
      :style="`background-image: url(${getImage(6)})`"
    />
    <div
      class="block block-7"
      :style="`background-image: url(${getImage(7)})`"
    />
    <div
      class="block block-8"
      :style="`background-image: url(${getImage(8)})`"
    />
    <div
      class="block block-9"
      :style="`background-image: url(${getImage(9)})`"
    />
    <div
      class="block block-10"
      :style="`background-image: url(${getImage(10)})`"
    />
  </div>
</template>

<script>
export default {
  name: "SponsorGallery",
  props: {},
  components: {},
  data: () => ({}),
  computed: {},
  methods: {
    getImage(num) {
      return require(`../../assets/img/sponsors-gallery/sponsor_gallery_grid${num}.png`);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";

.container {
  display: grid;
  grid-gap: 16px;
  grid-template-areas:
    "row-1 row-2 "
    "row-1 row-5 "
    "row-7 row-5 "
    "row-3 row-4 "
    "row-3 row-6 "
    "row-8 row-6 "
    "row-9 row-10 ";
}

.block {
  padding: 10px 10px;
  background-position: center;
  background-size: cover;
  background-color: #cccccc;
}

.block-1 {
  grid-area: row-1;
}
.block-2 {
  grid-area: row-2;
}
.block-3 {
  grid-area: row-3;
}
.block-4 {
  grid-area: row-4;
}
.block-5 {
  grid-area: row-5;
}
.block-6 {
  grid-area: row-6;
}
.block-7 {
  grid-area: row-7;
}
.block-8 {
  grid-area: row-8;
}
.block-9 {
  grid-area: row-9;
}
.block-10 {
  grid-area: row-10;
}

@media (min-width: $media-sm) {
  .container {
    grid-template-areas:
      "row-1 row-2 row-3 row-3 row-4"
      "row-1 row-5 row-3 row-3 row-6"
      "row-7 row-5 row-8 row-8 row-6"
      "row-9 row-9 row-9 row-10 row-10";
  }
}

@media (min-width: $media-lg) {
  .container {
    grid-gap: 24px;
  }
}
</style>
